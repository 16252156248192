<template>
  <b-modal id="modal-pieza"
    size="custom-lg-max"
    title="Seleccionar Pieza"
    @ok="guardarModal"
    @hidden="verificarModal"
    @cancel="cancelarGuardado"
  >
    <template #modal-header="{ close }">
      <div class="container row">
        <div class="col-sm-6 pl-0">
          <h5 class="modal-title">Seleccionar Pieza</h5>
        </div>
        <div class="col-sm-5 offset-sm-1">
          <button
            class="btn mr-2"
            :class="filter === 0 ? 'btn-success':'btn-light text-dark'"
            @click="mostrarTodos"
          >Todos</button>
          <button
            class="btn mr-2"
            :class="filter === 1 ? 'btn-success':'btn-light text-dark'"
            @click="mostrarOcupados"
          >Ocupado</button>
          <button
            class="btn"
            :class="filter === 2 ? 'btn-success':'btn-light text-dark'"
            @click="mostrarLibres"
          >Libre</button>
        </div>
      </div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="close()"
      >×</button>
    </template>
    <div class="row">
      <div class="col-sm-12">
        <b-table responsive class="table table-striped table-bordered table-hover"
          :fields="fieldsTabla"
          :items="tablaModalFiltrada"
        >
        <template #cell(estado)="data">
          <span :class="data.item.estado === 'Libre' ? 'text-success' : 'text-danger'">
            {{ data.item.estado }}
          </span>
        </template>
        <template #cell(importe_x_dia)="items">
          {{ items.item.importe_x_dia |  numeral('0,0.00') }}
        </template>
        <template #cell(accion)="items">
          <div class="col-sm-12" v-if="items.item.estado === 'Libre'">
            <input :id="'check'+items.index"
              v-model="items.item.checkInput"
              @input="resetCheckInput(items.item)"
              class="form-check-input"
              type="checkbox"
            >
          </div>
        </template>
        </b-table>
      </div>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <button class="btn btn-outline-dark" @click="cancel()">
        Cancelar
      </button>
      <button class="btn btn-warning" @click="ok()">
        Guardar
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'ModalPieza',
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fieldsTabla: [
        {
          key: 'piso_sala_nombre', label: 'Pieza', thStyle: { width: '21%' },
        },
        {
          key: 'cama_nombre', label: 'Cama', thStyle: { width: '15%' },
        },
        {
          key: 'clase_nombre', label: 'Clase', thStyle: { width: '15%' },
        },
        {
          key: 'importe_x_dia', label: 'Importe', thStyle: { width: '10%' },
        },
        {
          key: 'numero', label: 'N° Int.', thStyle: { width: '10%' },
        },
        {
          key: 'estado', label: 'Estado', thStyle: { width: '11%' },
        },
        {
          key: 'accion', label: '', thStyle: { width: '7%' },
        },
      ],
      filter: 2, // 0 = Todos , 1 = ocupado, 2 = libre
      store: false,
    };
  },
  created() {
    if (this.editMode) {
      this.store = true;
    }
  },
  methods: {
    guardarModal() {
      this.store = true;
    },
    cancelarGuardado() {
      this.store = false;
    },
    verificarModal() {
      if (this.store === false) {
        this.tablaModal.forEach((el, index) => {
          this.tablaModal[index].checkInput = false;
        });
      }
    },
    resetCheckInput(item) {
      this.tablaModal.forEach((el, index) => {
        if (el.id !== item.id) {
          this.tablaModal[index].checkInput = false;
        }
      });
    },
    mostrarTodos() {
      this.filter = 0;
    },
    mostrarOcupados() {
      this.filter = 1;
    },
    mostrarLibres() {
      this.filter = 2;
    },
    ...mapMutations('modalPieza', [
      'cargarModalPieza',
    ]),
  },
  computed: {
    ...mapFields('modalPieza', [
      'tablaModal',
    ]),
    tablaModalFiltrada() {
      if (this.filter === 1) {
        return this.tablaModal.filter((item) => (
          item.estado === 'Ocupado' || item.estado === 'Por Terminar'
        ));
      }
      if (this.filter === 2) {
        return this.tablaModal.filter((item) => item.estado === 'Libre');
      }
      return this.tablaModal;
    },
  },
};
</script>

<style scoped>
.text-success {
  color: green;
}
.text-danger {
  color: red;
}
</style>
